import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const ScreverPromo = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <ReactPlayer
      width={'100%'}
      height={'100vh'}
      // className={styles.video}
      playing={isPlaying}
      playsinline={true}
      controls={true}
      onClick={() => setIsPlaying(!isPlaying)}
      light={
        'https://wp.screver.com/wp-content/uploads/2022/08/promo-video-poster.jpg'
      }
      url={
        'https://wp.screver.com/wp-content/uploads/2022/08/screver-webm.webm'
      }
    />
  );
};

export default ScreverPromo;
